// Define following colors as primary, secondary, tertiary, quaternary, and quinary colors respectively
$color-primary: #14143A;
$color-secondary: #121364;
$color-tertiary: #020490;
$color-quaternary: #8284FF;
$color-quinary: #eeeeee;
// sixth color
$color-sixth: #d7d8ff;

$width-sm: 576px;
$width-md: 768px;
$width-lg: 992px;
$width-xl: 1200px;

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

////////////////// SCREEN FILL /////////////////////
html,
body,
#root,
.App {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

#box {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#boxcontent {
    display: flex;
    flex-direction: column;
    flex: 1;
}

#boxfooter {
    flex-shrink: 0;
}

////////////////// BASIC FORMATTING /////////////////////
.App {
    background-color: $color-quinary;
}

.container {
    max-width: $width-lg !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    color: $color-secondary !important;
}

h1 {
    text-align: center;
}

ul {
    margin-top: 0.5rem !important;
}

.spacious-text {
    font-size: 1.15rem;
    // text-align: justify;
    line-height: 1.6;
    margin-bottom: 0.5rem;
}

.emphasized-text {
    color: orangered;
    font-weight: bold;
    font-size: 1.25rem;
}

////////////////// NAVBAR /////////////////////
#navbar {
    // background-color: lighten($color-tertiary, 60%);
    background-color: $color-sixth;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    font-family: 'Montserrat', sans-serif;

    // set var color nav primary
    $color-nav-primary: $color-tertiary;
    * {
        color: $color-nav-primary;
    }

    .navbar-toggler {
        border-color: $color-nav-primary;;
    }

    .navbar-toggler .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    button span {
        color: $color-nav-primary !important;
    }

    .dropdown-menu {
        background-color: $color-tertiary;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 0.25rem 0.5rem;
        margin: 0.25rem;
        opacity: 0;
        transform: translateY(10px);
        transition: all 0.3s ease-in-out;
    }

    .nav-link a {
        transition: font-weight 0.2s ease-in-out, border 0.2s ease-in-out;
    }

    .nav-item.show .dropdown-menu {
        opacity: 1;
        transform: translateY(0);
    }

    .dropdown-item {
        padding: 10px 15px;
        font-size: 16px;
        color: white;
        transition: background-color 0.2s ease, color 0.2s ease;
        border-radius: 4px;

        &:hover {
            background-color: $color-quaternary;
            color: white;
        }

        &:focus:not(:hover) {
            background-color: inherit;
        }
    }

    .collapsible-nav-dropdown.active .dropdown-toggle {
        font-weight: bold;
    }

    .collapsible-nav-dropdown:not(.active) a.dropdown-toggle {
        font-weight: normal;
    }

    .collapsible-nav-dropdown a.active {
        font-weight: bold;
        background-color: $color-quaternary;
        border-radius: 4px;
    }

    // variable for the rotation:
    $rotate: 35deg;

    // Custom arrow styling for dropdown toggle
    .collapsible-nav-dropdown>.dropdown-toggle {
        position: relative;
        padding-right: 1.25rem; // Space for the arrow
    }

    .collapsible-nav-dropdown>.dropdown-toggle::before,
    .collapsible-nav-dropdown>.dropdown-toggle::after {
        content: '';
        border: 0;
        position: absolute;
        right: 0.5rem;
        width: 6px;
        height: 2px;
        background-color: $color-nav-primary;
        transition: transform 0.2s ease;
    }

    .collapsible-nav-dropdown>.dropdown-toggle::after {
        right: 0.25rem;
    }

    // Position the lines to form a downward arrow
    .collapsible-nav-dropdown>.dropdown-toggle::after {
        transform: rotate(-$rotate);
        top: 50%;
        transform-origin: center;
    }

    .collapsible-nav-dropdown>.dropdown-toggle::before {
        transform: rotate($rotate);
        top: 50%;
        transform-origin: center;
    }

    // Rotate lines to form an upward arrow when dropdown is open
    .collapsible-nav-dropdown.show>.dropdown-toggle::after {
        transform: rotate($rotate);
    }

    .collapsible-nav-dropdown.show>.dropdown-toggle::before {
        transform: rotate(-$rotate);
    }

    @media (max-width: $width-md) {
        .navbar-nav {
            // lessen gap
            gap: 0.1rem;
        }

        .collapsible-nav-dropdown, .dropdown-item {
            text-align: center;
            // width: 100%;
            // border-radius: 2px;

            // transition: ease-in-out 0.2s;

            // :hover {
            //     border: none;
            //     background-color: $color-quaternary;
            // }
        }
    }
}

@media (min-width: $width-sm) {
    .d-smg {
        display: inline-block !important;
    }

    .d-smg-none {
        display: none !important;
    }
}

@media (max-width: $width-sm) {
  .d-sms {
    display: inline-block !important;
  }

  .d-sms-none {
    display: none !important;
  }
}

////////////////// MEMBERS PAGE /////////////////////

#members-page {
    .member-list {
        margin-top: 1rem;
    }

    .member-item {
        margin-bottom: 1.5rem;
        text-align: center;
        font-size: 1.1em;
    }

    .delimiter {
        color: $color-quaternary; // Dark color for visibility
        margin-right: 0.4rem;
    }
}

////////////////// OTHER COMPETITIONS PAGE /////////////////////

#faqpage {}

////////////////// OTHER COMPETITIONS PAGE /////////////////////

#other-comp-page {
    .circle-style {
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        background-color: $color-quaternary;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .circle-style div {
        font-size: 1.5rem;
        font-weight: bold;
        color: white;
    }
}

////////////////// MEETING PAGE /////////////////////
#meetingpage {

    .card {
        height: 13.5rem;
        max-width: 20rem;
        margin: 0 auto;
    }

    .card-body {
        height: 100%;
    }

    .meeting-card-container {
        display: flex;
        align-items: flex-start;
        gap: 1rem;

        height: 100%;
    }

    .meeting-card-date-block {
        text-align: center;
        width: 2.26rem;
    }

    .meeting-card-date-month {
        font-size: 1rem;
        font-weight: bold;
        color: #007bff;
    }

    .meeting-card-date-day {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1;
    }

    .meeting-card-info {
        display: flex;
        flex-direction: column;
        max-height: 100%;
    }

    .meeting-card-desc {
        max-height: 100%;
        overflow-y: auto;
        // padding-bottom: 0.5rem;
    }

    .meeting-card-title {
        margin: 0;
    }

    .meeting-card-location {
        border-bottom: 2.5px dotted gray;
        cursor: pointer;
        flex: 0 0 auto;
    }

    .meeting-card-clock-icon {
        margin-bottom: 0.15rem;
    }

    .meeting-card-spacing {
        margin-bottom: 0.5rem;
    }

    .icon-text {
        font-size: 0.875rem;
        color: gray;
        margin: 0;
        margin-top: 0.2rem;
        padding: 0;
    }

    .card .icon {
        margin-right: 0.33em;
    }

    .card .meeting-time {
        margin-bottom: 1rem;
    }

    @media (max-width: $width-md) {
        .card {
            height: inherit;
        }

        .row {
            // gap: 1.5rem;
        }
    }
}


.leader-info {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .leader-image-wrapper {
        width: 100%;
        max-width: 12rem;
        height: 100%;
        max-height: 12rem;
        border-radius: 50%;
        overflow: hidden;
    }

    .leader-image {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    * {
        text-align: center;
    }

    h4 {
        font-size: 1.5rem;
        margin-top: 0.8rem;
    }
}

@media (max-width: $width-md) {

    .leader-info {
        margin-bottom: 1.5rem;
    }

    .leader-info:last-child {
        margin-bottom: 0;
    }

    .leader-info p {
        margin: 0 0.5rem;
    }
}

////////////////// HOME PAGE /////////////////////

#landing {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;

    .landing-container {
        $landing-container-color: rgb($color-primary, 0.5);
        margin: 0;
        background-size: cover;
        background-position: center;
        background-image: linear-gradient($landing-container-color, $landing-container-color), url('./components/images/home-jumbo.jpg');

        display: flex;
        flex-direction: column;
        flex: 1 0 auto;

        width: 100%;
        min-width: 100%;
    }

    .landing-logo-wrapper {
        display: flex;
        margin: auto;
        justify-content: center;
        align-items: center;
    }

    .landing-logo-container {
        width: 100%;
        max-width: 50vh;
        position: relative;
    }

    .landing-logo-image {
        opacity: 0.6;
        width: 100%;
        height: 100%;

        object-fit: contain;
        user-select: none;

        transition: opacity 0.3s ease-in-out;
        border-radius: 50%;

        &:hover {
            opacity: 1;
        }
    }


    .landing-welcome-title,
    .landing-description {
        color: white !important;
    }

    /* Additional style for the text center alignment */
    .text-center {
        text-align: center;
    }
}

@media (max-width: $width-md) {
    #landing {
        background-position: center 50%;
    }
}

#home-page {

    #welcome-title {
        color: white !important;
    }

    .discord-wrapper {
        width: 100%;
        // margin: 0 auto;
    }

    .discord-iframe {
        width: 100%;
        max-height: 25rem;
        // height: 100%;
    }


    @media (max-width: $width-md) {

        .leader-info {
            margin-bottom: 1.5rem;
        }

        .leader-info:last-child {
            margin-bottom: 0;
        }

        h1 {
            margin: 1rem 0 !important;
        }

        .leader-info p {
            margin: 0 0.5rem;
        }

        .discord-wrapper {
            margin-top: 1.5rem;
        }

        .discord-col {
            order: 2;
        }
    }
}

////////////////// ALTERNATING IMAGE /////////////////////
.alt-img-info,
.alt-img-image {
    padding: 1rem;
}

.alt-img-image img {
    border-radius: 10px;
}

@media(max-width: $width-md) {

    .alt-img-info *,
    .alt-img-image {
        text-align: center !important;
    }

    .alt-img-image {
        order: 1;
    }
}

////////////////// FOOTER /////////////////////
#footer {
    background-color: $color-primary !important;
    padding: 1rem;
    text-align: center;

    * {
        color: darken($color-quaternary, 10%);
    }

    span, a {
        color: $color-quaternary;
        text-decoration: none;
    }

    a {
        transition: color 0.2s ease-in-out;

        &:hover {
            color: mix($color-quaternary, $color-tertiary, 50%);
        }

        &:active {
            color: $color-tertiary;
        }
    }
}

////////////////// SECTIONS /////////////////////

.my-sections {
    .header-row-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .header-row {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: center;
    }

    .header-button {
        background-color: $color-quaternary;
        border-color: $color-quaternary;
        border-radius: 100px;
        padding: 0.5rem 1rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        flex-grow: 0;
        flex-shrink: 1;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: lighten($color-quaternary, 5%);
            border-color: lighten($color-quaternary, 5%);
        }

        &:active {
            background-color: lighten($color-quaternary, 10%);
            border-color: lighten($color-quaternary, 10%);
        }
    }
}

////////////////// ACCORDIANS /////////////////////

.my-accordian {
    .accordion-button {
        transition: font-weight 0.35s ease-in-out, box-shadow 0.35s ease-in-out, background-color 0.35s ease-in-out;
    }

    .accordion-button.collapsed,
    .accordion-button:not(.collapsed) {
        color: $color-primary;
        font-style: italic;
    }

    .accordion-button:not(.collapsed) {
        background-color: lighten($color-quaternary, 15%);
        font-weight: 600;
    }

    .accordion-button:focus {
        // box-shadow: 0 0 0 0.25rem lighten($color-quaternary, 15%);
        box-shadow: none;
    }

    .accordian-body {
        background-color: $color-quinary;
    }
}

////////////////// SPECIAL /////////////////////

// should change background to white, have a shadow & popout on hover
// .pop-out {
//     position: relative;
//     /* Ensure the pseudo-element is positioned relative to the parent */
//     transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out, border-radius 0.2s ease-in-out;

//     &:hover {
//         cursor: pointer;
//         background-color: white;
//         box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
//         transform: scale(1.1);
//         border-radius: 10px;
//         padding-left: 1rem;
//         padding-right: 1rem;
//     }
// }

.clickable {
    cursor: pointer;
    color: inherit;

    transition: all 0.2s ease-in-out;

    &:hover {
        color: $color-quaternary;
    }
}

.page-section {
    margin-top: 2rem;
}

.white-border {
    // gives white border to the element
    border: 1px solid white;
}

.circular {
    // makes element circular 
    border-radius: 50%;

}

@media (min-width: $width-md) {
    .enhanced {
        background-color: #fafafa;
        background-color: white;
        padding: 1rem;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
}